<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import router from "@/router";
import { HTTP } from "@/main-source";
import Swal from "sweetalert2";

export default {
  page: {
    title: "Surveys",
    meta: [{ name: "List of surveys" }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Surveys",
      surveys: [],
    };
  },
  created() {
    this.getSurveys();
  },
  methods: {
    addNew() {
      router.push({ name: "new-survey" });
    },
    getSurveys() {
      HTTP.get("surveys")
        .then((result) => {
          this.surveys = result.data;
        })
        .catch(() => {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Error while getting your data",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    },
    editSurvey(id) {
      router.push({ name: "survey-edit", params: { id } });
    },
    surveyDetail(id) {
      router.push({ name: "survey-detail", params: { id } });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" />
    <div class="process-row">
      <button type="button" class="btn btn-success" @click="addNew">
        + Add New
      </button>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table mb-0 table-hover">
                <thead>
                  <tr>
                    <th>Survey Name</th>
                    <th>Event</th>
                    <th>Created Date</th>
                    <th class="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(survey, index) in surveys" :key="index">
                    <td>{{ survey.name }}</td>
                    <td>
                      {{
                        survey.event ? survey.event.name : "No relational event"
                      }}
                    </td>
                    <td>
                      {{ new Date(survey.createdAt).toLocaleDateString("en") }}
                    </td>

                    <td width="130" class="text-center">
                      <button
                        class="btn btn-light btn-rounded"
                        @click="editSurvey(survey.id)"
                      >
                        <i class="ri-pencil-line"></i>
                      </button>
                      <button
                        class="btn btn-light btn-rounded ml-2"
                        @click="surveyDetail(survey.id)"
                      >
                        <i class="ri-folder-chart-line"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>